import '@babel/polyfill'
import 'mutationobserver-shim'
import Raphael from 'raphael/raphael'
global.Raphael = Raphael
import Vue from 'vue'
import './plugins'
import App from './App.vue'
import router from './router'
import store from './store'
import './directives'
import 'leaflet/dist/leaflet.css';

import axios from "axios";

const API_BASE_URL = process.env.VUE_APP_API_URL;

Vue.use({
    install(Vue) {
        Vue.prototype.$api = axios.create({
            baseURL: API_BASE_URL,
            withCredentials: true,
            timeout: 999999
        });
        Vue.prototype.$api.interceptors.response.use(
            function(response) {
                return response;
            },
            function(error) {
                if (error.response.status === 401 || error.response.status === 419) {
                    Vue.prototype.$api.get("/sanctum/csrf-cookie").then(() => {
                        window.location = `${API_BASE_URL}/login`;
                    });
                }
                if (error.response.status === 403) {
                    window.location = process.env.VUE_APP_URL;
                }
                return Promise.reject(error);
            }
        );
    },
});
/*
Vue.config.productionTip = false
Vue.prototype.$http = api;
api.defaults.timeout = 10000;

api.interceptors.request.use(
    config => {
      return config;
    },
    error => {
      return Promise.reject(error);
    }
);
const API_BASE_URL = process.env.VUE_APP_API_URL;


api.interceptors.response.use(
    response => {
        if (response.status === 200 || response.status === 201 || response.status === 204) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        if (error.response.status) {
            localStorage.setItem("login_publico", 1);
            switch (error.response.status) {
                case 400:
                    //do something
                    break;

                case 401:
                    Vue.prototype.$api.get("/sanctum/csrf-cookie").then(() => {
                        window.location = `${API_BASE_URL}/login`;
                    });
                    break;
                case 403:
                    // window.location.href="cursos-all";
                    break;
                case 404:
                    break;
                case 500:
                    break;
            }
            return Promise.reject(error.response);
        }
    }
);*/
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
